import React, { useState, useEffect } from 'react';
import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import { getTrailerHistorybyTrailerid } from "../../services/trailerService";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import Config from '../../Config.json';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { toast } from 'react-toastify';


const VendorPayable = (props) => {
    // Props (Which is also using as options like allWashLocation, allActiveTractors, allActiveTrailers)
    const { allWashLocation, allActiveTractors, allActiveTrailers, allWashCodes, createTankWash, loggedUserData, washDeatilsById, setorderClicked, updateTankWash, setSelectedWashLocation, trailerId, voidVal, selectedLocation, modalShow } = props;
    // States
    const [customer, setCustomer] = useState("");
    const [equipmentType, setEquipmentType] = useState({ id: "1", name: 'Trailer', value: "L" });
    const [interiorWash, setInteriorWash] = useState({});
    const [exteriorWash, setExteriorWash] = useState({});
    const [trailer, setTrailer] = useState("");
    const [tractor, setTractor] = useState("");
    const [washLocation, setWashLocation] = useState("");    
    const [order, setOrder] = useState("");
    const [movement, setMovement] = useState("");
    const [driver, setDriver] = useState("");
    const [commodity, setCommodity] = useState("");
    const [driverID, setDriverID] = useState("");   
    const [defaultDate1, setDefaultDate1] = useState(null);
    const [inDate, setInDate] = useState(null);
    const [outDate, setOutDate] = useState(null);
    const [scheduledStart, setScheduledStart] = useState(null);
    const [scheduledEnd, setScheduledEnd] = useState(null);

    // Disabled for now    
    const [maxWash, setMaxWash] = useState("");
    const [interiorWashAmount, setInteriorWashAmount] = useState(null);
    const [exteriorWashAmount, setExteriorWashAmount] = useState(null);
    const [bratsProduct, setBratsProduct] = useState("");
    const [allocation, setAllocation] = useState("");
    const [chargeAllocation, setChargeAllocation] = useState("");
    const [othersEquipment, setOthersEquipment] = useState("")
    // Options
    const [extrWashCodeOptions, setExtrWashCodeOptions] = useState([]);
    const [intrWashCodeOptions, setIntrWashCodeOptions] = useState([]);


    const equipmentTypeOptions = [
        { id: "1", name: 'Trailer', value: "L" },
        { id: "2", name: 'Tractor', value: "T" },
        { id: "3", name: 'Trailer & Tractor', value: "B" },
        { id: "4", name: 'Others', value: "O" },
    ];

    const handleEquipmentType = (e, value) => {
        setEquipmentType(value);
        setTractor({ "tractor_id": null });
        setTrailer({ "tractor_id": null });
        setOthersEquipment("");
    }

    useEffect(() => {
        // Filter the wash locations based on the values of is_wash and is_terminal
        const filteredLocations = allWashLocation.filter(
            (loc) => loc.is_wash === true && loc.is_terminal === false
        );
        setWashLocation(filteredLocations);
    }, [allWashLocation]);

    useEffect(() => {
        const extWashCodeArr = allWashCodes.filter((item) => item.type === "Exterior" && item.is_active === true);
        setExtrWashCodeOptions(extWashCodeArr);

        const intWashCodeArr = allWashCodes.filter((item) => item.type === "Interior" && item.is_active === true);
        setIntrWashCodeOptions(intWashCodeArr);
    }, []);

    const fetchData =async () => {
        setDriverID("");
        setDriver("");
        setOrder("");
        setMovement("");
   
        if (trailer?.trailer_id) {
            const getTailerHistoryById = await getTrailerHistorybyTrailerid(trailer.trailer_id);
            const trailerHistory = getTailerHistoryById.filter((trailer) => trailer?.planned_arrive_utc !== null);
            const trailerHistoryObject = trailerHistory[0];

            // setDriverID(`${trailerHistoryObject?.driver_id ? trailerHistoryObject?.driver_id : ""}`);

            if (trailerHistoryObject?.driver_id && trailerHistoryObject?.driver_first_name && trailerHistoryObject?.driver_last_name) {
                setDriver(`${trailerHistoryObject?.driver_id} - ${trailerHistoryObject?.driver_first_name} ${trailerHistoryObject?.driver_last_name}`);
            } else {
                setDriver(null);
            }

            // setOrder(trailerHistoryObject?.order_id);
            // setMovement(trailerHistoryObject?.movement_id);         
        }
    }
    
    useEffect( () => {
        fetchData();
    }, [trailer]);

    useEffect( () => {
        if (selectedLocation) {
            setWashLocation(selectedLocation);
        }
    }, [modalShow]);

    const handleDateChange = (date) => {
        if (washLocation?.timezone) {
            setDefaultDate1(date);
        } else {
            toast.error("Please first select wash location.", "Error", 2500);  
            setDefaultDate1(null);
        }
    }

    const handleInDateChange = (date) => {
        if (washLocation?.timezone) {
            setInDate(date); 
        } else {
            toast.error("Please first select wash location.", "Error", 2500);  
            setInDate(null);
        }
    }

    const handleOutDateChange = (date) => {
        if (washLocation?.timezone) {
            setOutDate(date); 
        } else {
            toast.error("Please first select wash location.", "Error", 2500);  
            setOutDate(null);
        }
    }

    const handleLocation = (e, value) => {
        setWashLocation(value);   

        if (setSelectedWashLocation !== undefined) {
            setSelectedWashLocation(value);
        }
    };

    const handleCreateHandler = async (e, defaultDate1, tz) => {
        if(!defaultDate1 || !tz){
            toast.error("Please first select wash location and needed by date", "Error", 2500);  
            return
        }

        let timestamps1 = DateTime.fromISO(defaultDate1?.toISO()).setZone(`${tz}`, { keepLocalTime: true }).toISO();
        let inDateTimestamp = DateTime.fromISO(inDate?.toISO()).setZone(`${tz}`, { keepLocalTime: true }).toISO();
        let outDateTimestamp = DateTime.fromISO(outDate?.toISO()).setZone(`${tz}`, { keepLocalTime: true }).toISO();

        const payload = {
            "__type": "trailer_wash_wo",
            "company_id": Config.companyid,
            "charge_type": "P",
            "commodity_id": commodity,
            "driver_id": driverID,
            "entered_user_id": loggedUserData.userName,
            "equip_type": equipmentType.value,
            "int_wash_code": interiorWash?.wash_code ? interiorWash?.wash_code : interiorWash?.int_wash_code,
            "ext_wash_code": exteriorWash?.wash_code ? exteriorWash?.wash_code : exteriorWash?.ext_wash_code,
            "intra_co_seg_code": "",
            "movement_id": movement,
            "order_id": order,
            // "ready_to_xfer": "N/A",
            "tractor_id": tractor?.tractor_id,
            "trailer_id": trailer?.trailer_id,
            "void": voidVal,
            "wash_location_id": washLocation?.code,
            // "trailer_wash_assigned": "N/A",
            "wash_date": defaultDate1?.toISO(),
            "needed_date": timestamps1,
            "source": "planning system",
            "other_equip": othersEquipment,
            "int_wash_amount": Number(interiorWashAmount),
            "ext_wash_amount": Number(exteriorWashAmount),
            "history":false,
            "in_date": inDateTimestamp,
            "out_date": outDateTimestamp,
        }

        if (washDeatilsById) {
            if (washDeatilsById && (payload.needed_date && payload.equip_type && payload.int_wash_code && payload.wash_location_id) && (payload.equip_type === "O" ? payload.other_equip : payload.equip_type === "L" ? payload.trailer_id : payload.equip_type === "T" ? payload.tractor_id : payload.equip_type === "B" ? (payload.tractor_id && payload.trailer_id) : false) && (((payload.out_date === null && payload.in_date !== null) || (payload.out_date === null && payload.in_date === null)) || ((payload.out_date !== null && payload.in_date !== null) && payload.out_date > payload.in_date))) {

                const response = await updateTankWash(payload, washDeatilsById.wash_id);        
                if (response.status !== 500) {
                    setorderClicked(false);
                } else {
                    setorderClicked(true);
                }
            } else {
                if ((payload.out_date !== null && payload.in_date !== null) && payload.in_date >= payload.out_date) {
                    toast.error("Out Date must be later than In Date.");
                } else if (payload.out_date !== null && payload.in_date === null) {
                    toast.error("Orders with an Out Date must also have an In Date.");
                }else {
                    toast.error("Please fill all mandatory fields.");
                }
            }

        } else {          
            if ((payload.needed_date && payload.equip_type && payload.int_wash_code && payload.wash_location_id) && (payload.equip_type === "O" ? payload.other_equip : payload.equip_type === "L" ? payload.trailer_id : payload.equip_type === "T" ? payload.tractor_id : payload.equip_type === "B" ? (payload.tractor_id && payload.trailer_id) : false) && (((payload.out_date === null && payload.in_date !== null) || (payload.out_date === null && payload.in_date === null)) || ((payload.out_date !== null && payload.in_date !== null) && payload.out_date > payload.in_date))) {
                createTankWash(payload);
            } else {
                if ((payload.out_date !== null && payload.in_date !== null) && payload.in_date >= payload.out_date) {
                    toast.error("Out Date must be later than In Date.");
                } else if (payload.out_date !== null && payload.in_date === null) {
                    toast.error("Orders with an Out Date must also have an In Date.");
                }else {
                    toast.error("Please fill all mandatory fields.");
                }
            }
        }
    }

    const movementTime = (epoch_date, tzone) => {      
        return DateTime.fromMillis(parseInt(epoch_date), {zone: tzone}).toISO({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false });          
    }

    useEffect(() => {
        if (washDeatilsById) {
            setExteriorWashAmount(washDeatilsById?.ext_wash.amount);
            setInteriorWashAmount(washDeatilsById?.int_wash.amount)
            setWashLocation(washDeatilsById.location)
            setInteriorWash(washDeatilsById.int_wash)
            setExteriorWash(washDeatilsById.ext_wash)
            if (washDeatilsById.equip_type == "L") {
                setEquipmentType({ name: "Trailer", value: "L" })
                setTrailer(washDeatilsById.trailer);
            }
            else if (washDeatilsById.equip_type == "T") {
                setEquipmentType({ name: "Tractor", value: "T" })
                setTractor(washDeatilsById.tractor)
            } else if (washDeatilsById.equip_type == "B") {
                setEquipmentType({ name: "Trailer & Tractor", value: "B" })
                setTractor(washDeatilsById.tractor);
                setTrailer(washDeatilsById.trailer)
            } else {
                setEquipmentType({ name: "Others", value: "O" })
                setOthersEquipment(washDeatilsById.other_equip)
            }
           
            if (washDeatilsById.needed_date_utc) {
                setDefaultDate1(DateTime.fromMillis(washDeatilsById.needed_date_utc, { zone: washDeatilsById.location?.timezone }));
            }
            if (washDeatilsById.in_date_utc) {
                setInDate(DateTime.fromMillis(washDeatilsById.in_date_utc, { zone: washDeatilsById.location?.timezone }));
            }
            if (washDeatilsById.out_date_utc) {
                setOutDate(DateTime.fromMillis(washDeatilsById.out_date_utc, { zone: washDeatilsById.location?.timezone }));
            }
            if (washDeatilsById.start_time_utc) {
                setScheduledStart(DateTime.fromISO(washDeatilsById.start_time_utc, { zone: washDeatilsById.location?.timezone }));
            }
            if (washDeatilsById.end_time_utc) {
                setScheduledEnd(DateTime.fromISO(washDeatilsById.end_time_utc, { zone: washDeatilsById.location?.timezone }));
            }
        }

        if (trailerId?.data?.trailer_id && trailerId?.addTrailerApi) {
            setTrailer(allActiveTrailers.find((el) => el.trailer_id === trailerId?.data?.trailer_id));
            setInteriorWash(allWashCodes.find((item) => (item.type === "I" || item.type === "Interior") && item.is_active === true && item.wash_code === trailerId?.data?.int_wash_cd));
            setExteriorWash(allWashCodes.find((item) => (item.type === "E" || item.type === "Exterior") && item.is_active === true && item.wash_code === trailerId?.data?.ext_wash_cd));
        }
    }, [washDeatilsById, trailerId]);

    const handleExteriorWashChange = (event, value) => {
        if (value) {
            setExteriorWash(value);
            setExteriorWashAmount(value?.amount);
        } else {
            setExteriorWash("");
            setExteriorWashAmount(null); 
        }
    };

    const handleInteriorWashChange = (event, value) => {
        if (value) {
            setInteriorWash(value);
            setInteriorWashAmount(value?.amount);
        } else {
            setInteriorWash("");
            setInteriorWashAmount(null);
        }
    };

    const extwashAmountHandler = (e) => {
        setExteriorWashAmount(Number(e.target.value));
    };

    const intwashAmountHandler = (e) => {
        setInteriorWashAmount(Number(e.target.value));
    };

    return (
        <>
            <div>
                <label htmlFor="typeHeadCommodityGroup">
                    Wash Location *
                </label>
                <div className="meterial_autocomplete">
                    <Autocomplete
                        id='combo-box-demo'
                        options={allWashLocation}
                        getOptionLabel={(option) => {
                            if (option.code) {
                                return `${option.code} - ${option.location_name} - ${option.state} - ${option.city}`;
                            }
                            return '';
                        }}
                        value={washLocation}
                        // value={selectedLocation}
                        onChange={(e, newValue) => handleLocation(e, newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder='Choose a Wash Location'
                                variant='outlined'
                            />
                        )}
                    />

                </div>
            </div>
            <div className="tankwash-dialog__container">
                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Equipment Type *
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            options={equipmentTypeOptions}
                            getOptionLabel={option => option.name}
                            onChange={(e, newValue) => handleEquipmentType(e, newValue)}
                            value={equipmentType}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Choose an Equipment Type"
                                    variant="outlined"

                                />
                            )}
                        />
                    </div>
                </div>

                {equipmentType?.name == 'Trailer' ?
                    <div className="dialog-item">
                        <label htmlFor="typeHeadCommodityGroup">
                            Trailer *
                        </label>
                        <div className="meterial_autocomplete">
                            <Autocomplete
                                disabled={equipmentType?.name === "Tractor"}
                                id='combo-box-demo'
                                options={allActiveTrailers}
                                getOptionLabel={(option) => {
                                    if (option.trailer_id) {
                                        return `${option?.trailer_id} ${option?.commoditygroup ? `- ${option?.commoditygroup.description} - ${option?.commoditygroup.code}` : ""}`;
                                    }
                                    return "";
                                }

                                }
                                value={trailer}
                                onChange={(event, newValue) => setTrailer(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder='Choose a Trailer'
                                        variant='outlined'
                                    />
                                )}
                            />
                        </div>
                    </div> : ""}
                {equipmentType?.name == 'Tractor' ?
                    <div className="dialog-item">
                        <label htmlFor="typeHeadCommodityGroup">
                            Tractor *
                        </label>
                        <div className="meterial_autocomplete">
                            <Autocomplete
                                disabled={equipmentType?.name === "Trailer"}
                                id='combo-box-demo'
                                options={allActiveTractors}
                                getOptionLabel={(option) => {
                                    if (option.tractor_id) {
                                        return `${option.tractor_id} - ${option.description}`
                                    }
                                    return "";
                                }

                                }
                                value={tractor}
                                onChange={(event, newValue) => setTractor(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder='Choose a Tractor'
                                        variant='outlined'
                                    />
                                )}
                            />
                        </div>
                    </div> : ""}
                {equipmentType?.name == 'Trailer & Tractor' ?
                    <>
                        <div className="dialog-item">
                            <label htmlFor="typeHeadCommodityGroup">
                                Trailer *
                            </label>
                            <div className="meterial_autocomplete">
                                <Autocomplete
                                    disabled={equipmentType?.name === "Tractor"}
                                    id='combo-box-demo'
                                    options={allActiveTrailers}
                                    getOptionLabel={(option) => {
                                        if (option.trailer_id) {
                                            return `${option?.trailer_id} ${option?.commoditygroup ? `- ${option?.commoditygroup.description} - ${option?.commoditygroup.code}` : ""}`;
                                        }
                                        return "";
                                    }

                                    }
                                    value={trailer}
                                    onChange={(event, newValue) => setTrailer(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Choose a Trailer'
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="dialog-item">
                            <label htmlFor="typeHeadCommodityGroup">
                                Tractor *
                            </label>
                            <div className="meterial_autocomplete">
                                <Autocomplete
                                    disabled={equipmentType?.name === "Trailer"}
                                    id='combo-box-demo'
                                    options={allActiveTractors}
                                    getOptionLabel={(option) => {
                                        if (option.tractor_id) {
                                            return `${option.tractor_id} - ${option.description}`
                                        }
                                        return "";
                                    }

                                    }
                                    value={tractor}
                                    onChange={(event, newValue) => setTractor(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Choose a Tractor'
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </> : ""
                }

                {equipmentType?.name == "Others" ?
                    <div className="dialog-item">
                        <label htmlFor="typeHeadCommodityGroup">
                            Other Equipment *
                        </label>

                        <div className="meterial_autocomplete">
                            <TextField
                                disabled={equipmentType?.name === "Tractor"}
                                id="outlined-required"
                                className="meterial_autocomplete"
                                variant='outlined'
                                placeholder='Enter Other Equipment'
                                value={othersEquipment}
                                onChange={(e) => { setOthersEquipment(e.target.value) }}
                            />
                        </div>
                    </div> : ""}

                <div className="dialog-item">
                    <label htmlFor="exteriorWashInput">
                        Exterior Wash (Optional)
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            options={extrWashCodeOptions}
                            getOptionLabel={option => {
                                if (option?.wash_code) {
                                    return `${option?.wash_code} - ${option?.description}`
                                }
                                return "";
                            }
                            }
                            onChange={handleExteriorWashChange}
                            value={exteriorWash}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="exteriorWashInput"
                                    placeholder="Choose an Exterior Wash"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Exterior Wash Amount
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            id="exteriorWashAmount"
                            value={exteriorWashAmount}
                            placeholder="Exterior Wash Amount"
                            variant="outlined"
                            fullWidth
                            onChange={extwashAmountHandler}
                            type="number"
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="interiorWashInput">
                        Interior Wash *
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            options={intrWashCodeOptions}
                            getOptionLabel={option => {
                                if (option?.wash_code) {
                                    return `${option?.wash_code} - ${option?.description}`
                                }
                                return "";
                            }
                            }
                            onChange={handleInteriorWashChange}
                            value={interiorWash}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="interiorWashInput"
                                    placeholder="Choose an Interior Wash"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Interior Wash Amount
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            id="interiorWashAmount"
                            value={interiorWashAmount}
                            placeholder="Interior Wash Amount"
                            variant="outlined"
                            fullWidth
                            onChange={intwashAmountHandler}
                            type="number"
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Max Wash
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disabled
                            options={[]}
                            getOptionLabel={option => option}
                            value={maxWash}
                            onChange={(event, newValue) => setMaxWash(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Max Wash"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Total Amount
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            fullWidth
                            variant='outlined'
                            type='number'
                            name='totalAmount'
                            placeholder='Total Amount'
                            size='small'
                            disabled
                            value={Number(interiorWashAmount) + Number(exteriorWashAmount)}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        {`Wash Needed By * ${!(washLocation?.timezone) ? "" : `(${washLocation?.timezone})`}`}
                    </label>
                    <div className="meterial_autocomplete">
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                slotProps={{
                                    textField: {
                                        error: false,
                                        fullWidth: true
                                    },
                                    actionBar: {actions: ['clear',"accept"]}
                                }}
                                orientation="landscape"
                                 disabled={washLocation?.timezone ? false : true}
                                value={defaultDate1}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                }}
                                onChange={handleDateChange}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Order
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            id="outlined-read-only-input"
                            value={order === null ? "No Data" : order}
                            InputProps={{
                                readOnly: true,
                            }}
                            placeholder="Order ID"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        In Date
                    </label>
                    <div className="meterial_autocomplete">
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                slotProps={{
                                    textField: {
                                        error: false,
                                        fullWidth: true
                                    },
                                    actionBar: {actions: ['clear',"accept"]}
                                }}
                                // disabled={washDetails?.location?.timezone ? false : true}
                                orientation="landscape"
                                value={inDate}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                }}
                                onChange={handleInDateChange}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Out Date
                    </label>
                    <div className="meterial_autocomplete">
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                slotProps={{
                                    textField: {
                                        error: false,
                                        fullWidth: true
                                    },
                                    actionBar: {actions: ['clear',"accept"]}
                                }}
                                // disabled={washDetails?.location?.timezone ? false : true}
                                orientation="landscape"
                                value={outDate}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                }}
                                onChange={handleOutDateChange}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Scheduled Start
                    </label>
                    <div className="meterial_autocomplete">
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                slotProps={{
                                    textField: {
                                        error: false,
                                        fullWidth: true
                                    },
                                    actionBar: {actions: ['clear',"accept"]}
                                }}
                                // disabled={washDetails?.location?.timezone ? false : true}
                                orientation="landscape"
                                value={scheduledStart}
                                disabled={true}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Scheduled End
                    </label>
                    <div className="meterial_autocomplete">
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                slotProps={{
                                    textField: {
                                        error: false,
                                        fullWidth: true
                                    },
                                    actionBar: {actions: ['clear',"accept"]}
                                }}
                                // disabled={washDetails?.location?.timezone ? false : true}
                                orientation="landscape"
                                value={scheduledEnd}
                                disabled={true}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                }}
                                // onChange={}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Movement
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            id="outlined-read-only-input"
                            value={movement === null ? "No Data" : movement}
                            InputProps={{
                                readOnly: true,
                            }}
                            placeholder="Movement ID"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Driver
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            id="outlined-read-only-input"
                            value= {(driverID === undefined || driverID === null) ? "" : driverID}
                            InputProps={{
                                readOnly: true,
                            }}
                            placeholder="Driver Details"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Commodity
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disabled
                            options={[]}
                            getOptionLabel={option => option}
                            value={commodity}
                            onChange={(event, newValue) => setCommodity(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Commodity ID"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        BRATS Product
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disabled
                            options={[]}
                            getOptionLabel={option => option}
                            value={bratsProduct}
                            onChange={(event, newValue) => setBratsProduct(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="BRATS Product"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Allocation
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disabled
                            options={[]}
                            getOptionLabel={option => option}
                            value={allocation}
                            onChange={(event, newValue) => setAllocation(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Allocation"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Charge Allocation
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disabled
                            options={[]}
                            getOptionLabel={option => option}
                            value={chargeAllocation}
                            onChange={(event, newValue) => setChargeAllocation(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Charge Allocation"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            <div style={{ textAlign: "right", padding: "20px 0px 20px 0px" }}>
                <Button variant="primary" onClick={(e) => handleCreateHandler(e, defaultDate1, washLocation?.timezone)} >{washDeatilsById ? "Save" : "Create"}</Button>
            </div>
        </>)
}

export default VendorPayable;
