import React, { useState, useEffect, useContext, createContext } from "react";
import Header from "../../components/header";
import PlannerHeader from "../../components/plannerHeader/plannerHeader";
import { gettrailerstabbydate } from "../../services/trailerService";
import {
  getBoardByTractorFormatted,
  getBoardByDriverFormatted,
  getDriverbyTerminalIDs,
  getmovementstopbyterminalid,
  getBoardByTrailerFormatted,
  getallcarrierview,
  addTrailer,
  changePlanTimebystartshift,
  scheduleSingleOrder,
  scheduleStackedOrders,
  addTrailerToorderfortrailerview,
  updateTrailerdatafortrailerview
} from "../../services/planningBoardService";
import { DateTime } from "luxon";
import { ContextData } from "../../components/appsession";
import ProgressBar from "react-bootstrap/ProgressBar";
import PlannerBoardTrailersTable from "../../components/plannerBoardTrailersTable/plannerBoardTrailersTable";
import PlannerBoardOrdersTable from "../../components/plannerBoardOrdersTable/plannerBoardOrdersTable";
import { makeStyles } from "@mui/styles";
import "../../components/planningboardRow/available.css";
import "../../components/planningboardRow/unavailable.css";
import { getPlanningProfileById, getAllPlanningProfiles } from "../../services/userService";
import "../plannerboard/plannermainsectionboard.css";
import PlannerBoardDriversection from "../../components/plannerboarddriversection/plannerboarddriversection";
import PlannerBoardTrailersection from "../../components/plannerboardtrailersection/plannerboardtrailersection";
import PlannerBoardCarrierViewsection from "../../components/plannerboardcarrierviewsection/plannerboardcarrierviewsection";
import PlannerBoardTractorsection from "../../components/plannerboardtractorsection/plannerboardtractorsection";
import PlannerBoardTopSection from "../../components/plannerboardtopsection/plannerboardtopsection";
import { useNavigate,useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { getorderdragdrop } from '../../utils/plannerutils'
import Autocomplete from "@mui/lab/Autocomplete";
import AppBar from "../../components/appbar";
import { getDecodedURI, getEncodedURI } from "../../utils/urlEncodeandDecode";
import WarningModal from '../../components/warningModal'
import PlannerBoardOrderTableTrailerview from "../../components/plannerBoardOrdersTable/plannerBoardOrderTableTrailerview";
import { getTrailerWashWorkOrdersforplanningboard } from "../../services/tankWashService";
import TankWashForPlanningBoard from "../../components/tankWashOrdersTable/tankWashForPlanningBoard";
import {getTractorByTerminalID} from "../../services/terminalService";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import pakageJson from '../../../package.json'
import { useFirebaseMessageAlert } from "../../firebase";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 500,
  },
  customTooltip: {
    maxWidth: "none",
    backgroundColor: "#4267B2",
    padding: "12px",
    borderRadius: "10px",
  },
  customArrow: {
    color: "#4267B2",
    fontSize: "20px",
  },
}));

const DropContext = createContext();
export { DropContext };

const Planner = () => {
  const [userData] = useContext(ContextData);
  const [dragData, setDragData] = useState({});
  const [dropData, setDropData] = useState({});
  const [isPlannerBoardClicked, setisPlannerBoardClicked] = useState(false);
  const [selectedTab, setselectedTab] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [allTrailers, setAllTrailers] = useState([]);
  const [allTractors, setAllTractors] = useState([]);
  const [PlannerBoardLoadingPage, setPlannerBoardLoadingPage] = useState(false);
  const [PlanningBoardDriversResponse, setallPlanningBoardDriversView] = useState([]);
  const [searchDriverData,setSearchDriverData]= useState([]);
  const [searchTrailerData,setSearchTrailerData]= useState([]);
  const [searchData, setSearchData] = useState("");
  const [PlanningBoardTractorresponse, setPlanningBoardTractorresponse] = useState([])
  const [driverPageNumber, setDriverPageNumber] = useState(1);
  const history = useNavigate();
  const location= useLocation();
  const [date, setDate] = useState(
    DateTime.now().toUTC().startOf("day").toMillis()
  );
  const [movementData, setMovementData] = useState([]);
  const [allTrailersArray, setAllTrailersArray] = useState([]);
  const [tabSelected, settabSelected] = useState("");
  const [plannerTimezone, setPlannerTimezone] = useState("utc");
  const [selectedTimezoneDate, setSelectedTimezoneDate] = useState(date);
  const [daysout, setDaysout] = useState(1);
  const [planningTimeout, setPlanningTimeout] = useState(null);
  const [datarefresh, setdatarefresh] = useState(false);
  const [driverViewPlannedMode, setDriverViewPlannedMode] = useState(false);
  const [oderTabClickC, setOderTabClickC] = useState(0);
  const [trailerTabClickC, setTrailerTabClickC] = useState(0);
  const [trailerTabLoading, setTrailerTabLoading] = useState(true)
  const [profile, setProfile] = useState();
  const [allPlanningProfile, setallPlanningProfile] = useState([])
  const [usersPlanningProfile, setusersPlanningProfile] = useState([])
  const [selectedPlanningProfile, setselectedPlanningProfile] = useState([]);
  const [palnningBoardLoading, setpalnningBoardLoading] = useState(true)
  const [plannedAcctualToggle, setplannedAcctualToggle] = useState("")
  const [payloadForOrders, setpayloadForOrders] = useState({});
  const [planningBoardData, setPlanningBoardData] = useState({ data: [] });
  const [driverData, setDriverData] = useState([]);
  const [payloadForRefreshData, setPayloadForRefreshData] = useState(null);
  const [plannedOrUnplanned, setPlannedOrUnplanned] = useState("center");
  const [trailerViewPageNumber, settrailerViewPageNumber] = useState(1)
  const [tarilerViewData, settarilerViewData] = useState([])
  const [carrierviewData, setcarrierviewData] = useState([])
  const [tractorData, settractorData] = useState([])
  const [carrierPageNumber, setCarrierPageNumber] = useState(1);
  const [tractorPageNumber, setTractorPageNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [washDetails, setWashDetails] = useState({});
  const hourArr = Array.from(Array(24).keys());
  const dragset = Array.from(Array(96).keys());
  const [isEditPermission, setisEditPermission] = useState(false);
  const [isViewPermission, setViewPermission] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [daysOutLoader, setdaysOutLoader] = useState(false)
  const [PlannedTab, setplannedTab] = useState(false);
  const [selectedCommodityGroup, setSelectedCommodityGroup] = useState("");
  const [findTrailersBasedOnCommodity, setFindTrailersBasedOnCommodity] = useState(false);
  const [driverMessageDetails, setDriverMessageDetails] = useState({});
  const [driverLoadUnloadFlag, setDriverLoadUnloadFlag] = useState(false)
  const [loadchange, setloadChange] = useState({})
  const [unloadChange, setunloadChange] = useState({})
  const [puloadflag, setpuloadflag] = useState([{
    driver_load_unload: "Y",
  },
  {
    driver_load_unload: "N"
  }])
  const [orderRefreshState,setOrderRefreshState]=useState(false);
  const [successResponseMssg, setSuccessResponseMssg ] = useState(null);
  const [afterRefreshshowmsg,setAfterRefreshshowmsg]=useState(false)
  const [delloadflag, setdelloadflag] = useState([{
    driver_load_unload: "Y",
  },
  {
    driver_load_unload: "N"
  }])
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
 const [warningModalOpen, setWarningModalOpen] =  useState(null);
 const [realTimeofPlanningBoard,setRealTimeofPlanningBoard] = useState(date);
 const[alltankwash,setAllTankwash] = useState([]);
 const [version,setVersion] = useState(localStorage.getItem('appVersion'));
  const { notificationData } = useFirebaseMessageAlert();

 const dayToMillis = 86400000;
  const max_rest_duration_of_driver = 10 * 60 * 60 * 1000;

  useStyles();

  useEffect(()=>{
    let params=getDecodedURI(location.search);
     if(params?.view){
        setselectedTab(params.view)
        }else{
          setselectedTab('driver')
        }
     if(params?.mode){
      setplannedAcctualToggle(params?.mode==='planned' ?  'left':'center');
      setDriverViewPlannedMode(params?.mode==='planned' ? true : false);
     }else{
      setplannedAcctualToggle('center');
     }   
   },[])

  useEffect(() => {
    if (tabSelected === "orders") {
      setOderTabClickC(prev => prev + 1)
    }
    if (tabSelected === "trailers") {
      setTrailerTabClickC(prev => prev + 1)
    }

  }, [tabSelected])

  const getTrailerTabData = async (movementData, driverData, trailerinfo, plannedAcctualToggle, trailerViewPageNumber) => {

    let BoardByTrailerFormatted = await getBoardByTrailerFormatted(
      movementData,
      driverData,
      trailerinfo,
      plannedAcctualToggle
    );
    if(searchData && searchData !== ''){
      searchHandler(BoardByTrailerFormatted,true)
     }else{
      setSearchTrailerData(BoardByTrailerFormatted?.slice((trailerViewPageNumber - 1) * 25)?.filter((el,i)=>i<25))
     }
    if (BoardByTrailerFormatted) {
      settarilerViewData(BoardByTrailerFormatted)
      return BoardByTrailerFormatted;
    }
  };

const getTractorTabData =async (movementData, driverData, tractorData, plannedAcctualToggle, tractorPageNumber) => {

  let BoardByTractorFormatted = await getBoardByTractorFormatted(
    movementData,
    // tractorData,
    tractorData.slice((tractorPageNumber - 1) * 25,
    tractorPageNumber * 25),
    driverData,
    plannedAcctualToggle
  );
console.log("BoardByTractorFormatted",BoardByTractorFormatted)
  if (BoardByTractorFormatted) {
    setPlanningBoardTractorresponse(BoardByTractorFormatted)
    settractorData(BoardByTractorFormatted)
    return BoardByTractorFormatted;
  }
};

  useEffect(() => {
    refreshData(payloadForRefreshData, movementData, driverData, tarilerViewData, true,null)
  }, [trailerViewPageNumber]);

  const quickRefresh = async (mvmtData, plannedMode) => {
    setDriverViewPlannedMode(plannedMode);
    console.log({
      mvmtData,
      plannedMode
    })
    if ((selectedTab !== 'trailer' && selectedTab !== 'tractor')) {
    let BoardByDriverFormatted = await getBoardByDriverFormatted(
      mvmtData,
      allTrailers,
      driverData,
      plannedMode
    );
    let driverArr=BoardByDriverFormatted?.sort((a,b)=>Number(a.driver_id)-Number(b.driver_id))
    console.log(driverArr)
    setallPlanningBoardDriversView(driverArr);
    if(searchData && searchData !== ''){
      searchHandler(driverArr)
    }else{
    setSearchDriverData(driverArr)
  }
  }else if(selectedTab == 'trailer'){
    getTrailerTabData(mvmtData, driverData, allTrailers, plannedMode, trailerViewPageNumber);
  }else{
    getTractorTabData(mvmtData, driverData, allTractors, plannedMode, tractorPageNumber);
  }
  };

  useEffect(() => {
    if (notificationData?.data && notificationData?.isSilentNotification) {
      silentPlanningBoardUpdate();
    }
  }, [notificationData]);

  const silentPlanningBoardUpdate = async () => {
    try {
      if (!notificationData?.data || !notificationData?.isSilentNotification) {
        return;
      }

      const silentNotificationPayloadData = notificationData?.data;

      const reqOrderId = silentNotificationPayloadData?.orderId === "undefined" ? undefined : silentNotificationPayloadData?.orderId;
      const reqLoadId = silentNotificationPayloadData?.loadId === "undefined" ? undefined : silentNotificationPayloadData?.loadId;
      const reqStopId = silentNotificationPayloadData?.stopId === "undefined" ? undefined : silentNotificationPayloadData?.stopId;
      const actual_arrival = silentNotificationPayloadData?.actual_arrival === "undefined" ? undefined : silentNotificationPayloadData?.actual_arrival;
      const actual_departure = silentNotificationPayloadData?.actual_departure === "undefined" ? undefined : silentNotificationPayloadData?.actual_departure;
  
      console.log({
        message: "Silent notification received",
        payload: {
          orderId: reqOrderId,
          loadId: reqLoadId,
          stopId: reqStopId,
          actual_arrival,
          actual_departure
        },
        movementData
      });
  
      if (movementData?.data?.length && reqOrderId && reqLoadId) {
        console.log("Driver Updated Planning Status. Updating order status.");
        let reqMovement = movementData.data.filter((move) => move.order_id2 === reqOrderId && move.load_id === reqLoadId);
  
        console.log(reqMovement)
        if (!reqMovement.length) {
            console.error("MovementData is empty");
            return;
        }
  
        reqMovement = reqMovement[0];
        reqMovement.xmitted2driver_utc = 1;
        reqMovement.xmit_accepted = 1;
  
        const updatedMovementData = {
          data: [
            ...movementData.data.filter((move) => move.load_id !== reqLoadId),
            reqMovement
          ]
        }
  
        refreshData(payloadForRefreshData, updatedMovementData, driverData, true,null);
        setMovementData(updatedMovementData);
      }
  
      if (movementData?.data?.length && reqStopId) {
        console.log("Driver Updated Planning Status. Updating movements.");
  
        const pickupMovementData = movementData.data.filter((movement) => movement.pickup.lm_stop_id === reqStopId);
        const deliveryMovementData = movementData.data.filter((movement) => movement.delivery.lm_stop_id === reqStopId);
  
        console.log({
          message: 'original',
          pickupMovementData,
          deliveryMovementData
        })
  
        if (pickupMovementData.length) {
          pickupMovementData.forEach((movement) => {
            if (actual_arrival && actual_departure) {
              movement.pickup.actual_arrival_utc = actual_arrival === "null" ? 0 : Number(actual_arrival);
              movement.pickup.actual_departure_utc = actual_departure === "null" ? 0 : Number(actual_departure);
              movement.pickup.eta_arrival_utc = actual_arrival === "null" ? 0 : Number(actual_arrival);
              movement.pickup.eta_departure_utc = actual_departure === "null" ? 0 : Number(actual_departure);
            }
  
            if (actual_arrival && !actual_departure) {
              movement.pickup.actual_arrival_utc = actual_arrival === "null" ? 0 : Number(actual_arrival);
              movement.pickup.eta_arrival_utc = actual_arrival === "null" ? 0 : Number(actual_arrival);
            }
            
            if (!actual_arrival && actual_departure) {
              movement.pickup.actual_departure_utc = actual_departure === "null" ? 0 : Number(actual_departure);
              movement.pickup.eta_departure_utc = actual_departure === "null" ? 0 : Number(actual_departure);
            }
          });
        }
  
        if (deliveryMovementData.length) {
          deliveryMovementData.forEach((movement) => {
            if (actual_arrival && actual_departure) {
              movement.delivery.actual_arrival_utc = actual_arrival === "null" ? 0 : Number(actual_arrival);
              movement.delivery.actual_departure_utc = actual_departure === "null" ? 0 : Number(actual_departure);
              movement.delivery.eta_arrival_utc = actual_arrival === "null" ? 0 : Number(actual_arrival);
              movement.delivery.eta_departure_utc = actual_departure === "null" ? 0 : Number(actual_departure);
            }
  
            if (actual_arrival && !actual_departure) {
              movement.delivery.actual_arrival_utc = actual_arrival === "null" ? 0 : Number(actual_arrival);
              movement.delivery.eta_arrival_utc = actual_arrival === "null" ? 0 : Number(actual_arrival);
            }
            
            if (!actual_arrival && actual_departure) {
              movement.delivery.actual_departure_utc = actual_departure === "null" ? 0 : Number(actual_departure);
              movement.delivery.eta_departure_utc = actual_departure === "null" ? 0 : Number(actual_departure);
            }
          });
        }
  
        console.log({
          message: 'Updated',
          pickupMovementData,
          deliveryMovementData
        })
        let loadIds=[...pickupMovementData,...deliveryMovementData].map((el)=>el.load_id)
        let movementRes=movementData.data.filter((movement) => !loadIds.includes(movement.load_id));
  
        const updatedMovementData = {
          data: [
            ...pickupMovementData,
            ...deliveryMovementData,
            ...movementRes
          ]
        }
  
        console.log({
          updatedMovementData
        })

        refreshData(payloadForRefreshData, updatedMovementData, driverData, true,null);
        setMovementData(updatedMovementData);
      }
    } catch (err) {
      console.error(err);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Failed to process silent messages for planner board update', { autoClose: false } );
      }
    }
  }

  useEffect(() => console.log(PlanningBoardDriversResponse), [PlanningBoardDriversResponse])

  useEffect(() => {
    if (selectedTab === "carrier") {
      setpalnningBoardLoading(true)
      let payload = {
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      }
      carrierviewmovementdata(payload)
    } else {
      let payload = {
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      };
      setpalnningBoardLoading(true);
      refreshDataFromAPI(payload);
      setpayloadForOrders({
        "selectedTimezoneDate": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id],
        "daysOut": daysout,
        "plannerTimezone":plannerTimezone,
        "selectedPlanningProfile":selectedPlanningProfile
      });
    }
  }, [
    selectedPlanningProfile,
    selectedTimezoneDate,
    selectedTab
  ]);

  useEffect(() => {
    setTrailerTabLoading(PlannerBoardLoadingPage)
  }, [PlannerBoardLoadingPage]);

  useEffect(() => {
    setpayloadForOrders({
      "selectedTimezoneDate": selectedTimezoneDate,
      "terminals": [selectedPlanningProfile.terminal_id],
      "daysOut": daysout,
      "plannerTimezone":plannerTimezone,
      "selectedPlanningProfile":selectedPlanningProfile
    })
  }, [selectedPlanningProfile.terminal_id]);

  useEffect(()=>{
    if(successResponseMssg!==null){
      toast.success(successResponseMssg,'Success')
      setSuccessResponseMssg(null);
    }
  },[afterRefreshshowmsg])

  const refreshDataFromAPI = async (payload) => {
    
    if (planningTimeout) clearTimeout(planningTimeout);
    setPlanningTimeout(
      setTimeout(async () => {
        let api_res = null;
        setTrailerTabLoading(true)

        if (payload?.terminals?.length > 0 && payload?.terminals[0]) {
          try {
            api_res = await Promise.all([getmovementstopbyterminalid(payload,selectedPlanningProfile?.terminal?.timezone,/^[a-zA-Z ]+$/.test(searchData) ? null :searchData, payload.selectedTab ? payload.selectedTab : plannedAcctualToggle ), getDriverbyTerminalIDs(payload)]);
          } catch(err){
            console.log(err)
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              errorMessageDisplay(err?.response?.data?.errors);
            }else{
            toast.error('Failed to refresh data from api', { autoClose: false } );
            }
          }
         
          if (!api_res || !api_res[0] || !api_res[1]) return;
          setMovementData(api_res[0]);
          setDriverData(api_res[1]?.sort((a,b)=>Number(a.driver_id)-Number(b.driver_id)));
          setTrailerTabLoading(false);
          setAfterRefreshshowmsg(!afterRefreshshowmsg)
        }
        if (!api_res || !api_res[0] || !api_res[1]) return;
        setPayloadForRefreshData(payload);
        refreshData(payload, api_res[0], api_res[1]?.sort((a,b)=>Number(a.driver_id)-Number(b.driver_id)),false, payload?.selectedTab ? payload?.selectedTab : null);
      }, 1500)
    );

    setDragData({});
    localStorage.removeItem("draggedData");
  };

  useEffect(() => {
    setAllTrailersArray(allTrailers);
  }, [allTrailers.length]);

  useEffect(() => {
   let dragDataValue=JSON.parse(localStorage.getItem('draggedData'))
   if(dragDataValue){
    localStorage.removeItem("draggedData");
   }
  }, []);

  const refreshData = async (searchparams, movementDataForRefresh, driData, noreload = false, plannedOrActual) => {
    setTrailerTabLoading(true);

    //Load Planning Board View From Service
    //Pull and Send The Correct Date Here
    if (searchparams?.terminals?.length > 0) {
      let tab_res = [allTrailers];
      let plannedOrActualValue = driverViewPlannedMode;
      if (plannedOrActual && plannedOrActual !== null) {
        plannedOrActualValue = plannedOrActual === "left"
        setDriverViewPlannedMode(plannedOrActual === "left")
      }

      if ((selectedTab !== 'trailer' && selectedTab !== 'tractor')) {
        const planningBoardDriverResponse = await getBoardByDriverFormatted(
          movementDataForRefresh,
          tab_res[0],
          driData,
          plannedOrActualValue
        ); //Move Into Drivers Logic
        let driverArr=planningBoardDriverResponse?.sort((a,b)=>Number(a.driver_id)-Number(b.driver_id))
        setallPlanningBoardDriversView(driverArr);
        if(searchData && searchData !== ''){
         searchHandler(driverArr,true)
        }else{
        setSearchDriverData(driverArr?.slice((driverPageNumber - 1) * 25)?.filter((el,i)=>i<25))
        }
      }else if((selectedTab === 'tractor')){
        const terminalId = searchparams.terminals[0];
        const tractorResponse = await getTractorByTerminalID(terminalId);
        const sortTractorRes=tractorResponse?.sort((a,b)=>parseInt(a.tractor_id,36)-parseInt(b.tractor_id,36))
        setAllTractors(sortTractorRes);
        getTractorTabData(movementDataForRefresh, driData, sortTractorRes, plannedOrActualValue, tractorPageNumber);
       
     }
      else {
        const trailerResponse = await gettrailerstabbydate(searchparams.terminals);
        const sortTrailerRes=trailerResponse?.sort((a,b)=>parseInt(a.trailer_id,36)-parseInt(b.trailer_id,36))
        setAllTrailers(sortTrailerRes);
        getTrailerTabData(movementDataForRefresh, driData, sortTrailerRes, plannedOrActualValue, trailerViewPageNumber);
      }
      setRealTimeofPlanningBoard(DateTime.now().setZone(plannerTimezone).toMillis())
      if (!noreload) {
        setOrderRefreshState(!orderRefreshState);
        localStorage.setItem("refreshOrderTab",true);
      }

      setpalnningBoardLoading(false)
      setPlannerBoardLoadingPage(false);
      setTrailerTabLoading(false);
    } else {
      setAllOrders([]);
      setAllTrailers([]);
      setAllTractors([]);
      setPlannerBoardLoadingPage(false);
      setallPlanningBoardDriversView([]);
      setSearchDriverData([]);
      setSearchTrailerData([]);
      setSearchData("");
    }
  };

  const searchHandler = async (data, refresh) => {
    if (selectedTab == "trailer") {
      if (searchData) {
        let driverMovements = [];
        if (refresh) {
          if (data?.filter((trailer) => trailer.trailer_id == searchData?.trim())?.length > 0 || /^[a-zA-Z ]+$/.test(searchData)) {
            setSearchTrailerData(data?.filter((trailer) => trailer.trailer_id == searchData?.trim()))
            return;
          }
          driverMovements = [...data]?.slice((trailerViewPageNumber - 1) * 25)?.filter((el,i)=>i<25);
        } else {
          let tab_res = [allTrailers];
          let payload = { "date": selectedTimezoneDate, "terminals": [selectedPlanningProfile.terminal_id] };
          let movement_Data = await getmovementstopbyterminalid(payload, selectedPlanningProfile?.terminal?.timezone,/^[a-zA-Z ]+$/.test(searchData) ? null : searchData,plannedAcctualToggle);
          driverMovements = await getBoardByTrailerFormatted(movement_Data, driverData, tab_res[0], driverViewPlannedMode);
          if (driverMovements?.filter((trailer) => trailer.trailer_id == searchData?.trim())?.length > 0 || /^[a-zA-Z ]+$/.test(searchData)) {
            setSearchTrailerData(driverMovements?.filter((trailer) => trailer.trailer_id == searchData?.trim()))
            return;
          }
        }
        setSearchTrailerData(driverMovements?.filter((trailer) => trailer?.movements?.length > 0));
      } else {
        let tab_res = [allTrailers];
        let payload = { "date": selectedTimezoneDate, "terminals": [selectedPlanningProfile.terminal_id] };
        let movement_Data = await getmovementstopbyterminalid(payload, selectedPlanningProfile?.terminal?.timezone, searchData,plannedAcctualToggle);
        let driverMovements = await getBoardByTrailerFormatted(movement_Data, driverData, tab_res[0], driverViewPlannedMode);
        setSearchTrailerData(driverMovements?.slice((trailerViewPageNumber - 1) * 25)?.filter((el,i)=>i<25))
      }
    } else {
      if (searchData) {
        let driverMovements = [];
        if (refresh) {
          if (data?.filter((driver) => driver.driverId == searchData?.trim() || driver.driver_full_name?.toUpperCase()?.includes(searchData?.trim()?.toUpperCase()))?.length > 0 || /^[a-zA-Z ]+$/.test(searchData)) {
            setSearchDriverData(data?.filter((driver) => driver.driverId == searchData?.trim() || driver.driver_full_name?.toUpperCase()?.includes(searchData?.trim()?.toUpperCase())))
            return;
          }
          driverMovements = [...data]?.slice((driverPageNumber - 1) * 25)?.filter((el,i)=>i<25);
        } else {
          let tab_res = [allTrailers];
          let payload = { "date": selectedTimezoneDate, "terminals": [selectedPlanningProfile.terminal_id] };
          let movement_Data = await getmovementstopbyterminalid(payload, selectedPlanningProfile?.terminal?.timezone,/^[a-zA-Z ]+$/.test(searchData) ? null : searchData,plannedAcctualToggle);
          driverMovements = await getBoardByDriverFormatted(movement_Data, tab_res[0], driverData, driverViewPlannedMode);
          if (driverMovements?.filter((driver) => driver.driverId == searchData?.trim() || driver.driver_full_name?.toUpperCase()?.includes(searchData?.trim()?.toUpperCase()))?.length > 0 || /^[a-zA-Z ]+$/.test(searchData)) {
            setSearchDriverData(driverMovements?.filter((driver) => driver.driverId == searchData?.trim() || driver.driver_full_name?.toUpperCase()?.includes(searchData?.trim()?.toUpperCase())))
            return;
          }
        }
        setSearchDriverData(driverMovements?.filter((driver) => driver?.movements?.length > 0));
      } else {
          let tab_res = [allTrailers];
          let payload = { "date": selectedTimezoneDate, "terminals": [selectedPlanningProfile.terminal_id] };
          let movement_Data = await getmovementstopbyterminalid(payload, selectedPlanningProfile?.terminal?.timezone, searchData,plannedAcctualToggle);
          let driverMovements = await getBoardByDriverFormatted(movement_Data, tab_res[0], driverData, driverViewPlannedMode);
          setSearchDriverData(driverMovements?.slice((driverPageNumber - 1) * 25)?.filter((el,i)=>i<25))
      }
    }
  };

  const handelcallbackFromHeader = async (childdata) => {
    setisPlannerBoardClicked(childdata);
    setselectedTab("");
  };

  const parrentCallBackForTab = (data) => {
    setselectedTab(data);
  };

  useEffect(() => {
    refreshData(payloadForRefreshData, movementData, driverData, true,null);
  }, [driverPageNumber]);

  //for tractor page change
  useEffect(() => {
    refreshData(payloadForRefreshData, movementData, tractorData, true,null);
  }, [tractorPageNumber]);

  const checkAccess = () => {
    const permission = userData?.roles?.rolespermission
      .map(permit => {
        if (permit?.permissiondetail.code === "Planning") {
          setisEditPermission(permit?.can_edit);
          setViewPermission(permit?.can_view);
        }
      });
  }

  useEffect(() => {
    checkAccess()
  }, [userData]);

  // For calculating drop index times
  const calculateIndexTime = function (drop_data, selected_tz_date) {
    const miliInhour = 60 * 60 * 1000;
    const hour = drop_data.index / 4;
    const splitHourData = (hour - Math.floor(hour)) * 60;
    const dropIndexCalculatedTime = selected_tz_date + (parseInt(Math.floor(hour)) * miliInhour) + (splitHourData * 60 * 1000);

    let dropTimeObj = {
      dropTimeInISO: DateTime.fromMillis(parseInt(dropIndexCalculatedTime)).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
      dropTimeInMillis: dropIndexCalculatedTime,
    };

    return dropTimeObj;
  };

  async function scheduleSingleAndStackOrder(allMovementsOfSelectedDriver, dropTime, dragDataItems, driver, driver_load, driver_unload) {
    let lastEndShiftOfDriver = {};
    let diffBetweenDropIndexToLastEndShift = null;

    // Finding if any pre scheduled orders or not.
    if (allMovementsOfSelectedDriver.length > 0) {
      // All end shifts of driver
      const allEndShiftsOfDriver = allMovementsOfSelectedDriver.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null && parseInt(mov?.movementData?.delivery?.planned_depart_utc) <= dropTime.dropTimeInMillis).sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);

      // Getting exact last end shift before dropIndex
      lastEndShiftOfDriver = allEndShiftsOfDriver?.length > 0 ? allEndShiftsOfDriver[allEndShiftsOfDriver.length - 1] : null;

      // Difference between dropIndex to lastEndShift
      if(plannedAcctualToggle=='left'){
      diffBetweenDropIndexToLastEndShift = lastEndShiftOfDriver ? Math.abs(dropTime.dropTimeInMillis - lastEndShiftOfDriver?.movementData.delivery.planned_depart_utc) : null;
      }else{
      diffBetweenDropIndexToLastEndShift = lastEndShiftOfDriver ? Math.abs(dropTime.dropTimeInMillis - lastEndShiftOfDriver?.movementData.delivery.eta_departure_utc) : null;
      }
    };

    if (diffBetweenDropIndexToLastEndShift === null || diffBetweenDropIndexToLastEndShift >= max_rest_duration_of_driver || lastEndShiftOfDriver?.movementData.delivery.actual_arrival_utc>0) {
      try {
        // If there is some pre scheduled orders and difference between drop index time and last end shift is less than 10hrs then order will be stacked.
        let payload = {
          movement_id: dragDataItems.Move_ID,
          planning_date: dropTime.dropTimeInISO,
          trailer_id: dragDataItems.Trailer_ID,
        };

        if (driver_load) {
          payload.loadFlag = driver_load;
        }

        if (driver_unload) {
          payload.unloadFlag = driver_unload;
        }

        // Calling scheduleSingleOrder API for scheduling an order.
        const data = await scheduleSingleOrder(driver.driverId, payload);
        if(data?.data?.status=='warning'){
          let str=data?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
          let pickupAndDelivery = [];

          if (data?.data?.options.length > 0) {
            const changeTrailerDestination = data?.data?.options.find((el) => el?.name === "Change trailer destination");
            
            if (changeTrailerDestination !== undefined) {
              pickupAndDelivery = driver.movements.filter((mov) => mov.stopData === null && mov.trailer_id === changeTrailerDestination.data.trailer_id);
            }
          }

          setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}  warningMasterData={data?.data?.options ?? []}  payload={payload} driver={driver} ApiName={'scheduleSingleOrder'} setSuccessResponseMssg={setSuccessResponseMssg} errormssg={str} pickupAndDelivery={pickupAndDelivery} /></div>)});
           return
        }else if(data?.data?.status=='failure'){
         errorMessageDisplay(data?.data?.errors);
        }else{
       setSuccessResponseMssg("Order scheduled successfully.")
        }
      } catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to schedule single and stack order', { autoClose: false } );
        }
      }
    } else {
      try {
        // If there is some pre scheduled orders and difference between drop index time to last end shift is less than 10hrs then order will be stacked.
        let payload = {
          movement_id: dragDataItems.Move_ID,
          trailer_id: dragDataItems.Trailer_ID
        };

        if (driver_load) {
          payload.loadFlag = driver_load;
        }

        if (driver_unload) {
          payload.driver_unload = driver_unload;
        }

        const planId = lastEndShiftOfDriver.movementData.delivery.plan_id;

        // Calling scheduleSingleOrder API for scheduling an order.

        const data = await scheduleStackedOrders(driver.driverId, planId, payload);
        if(data?.data?.status=='warning'){
          let str=data?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
          let pickupAndDelivery = [];

          if (data?.data?.options.length > 0) {
            const changeTrailerDestination = data?.data?.options.find((el) => el?.name === "Change trailer destination");
            
            if (changeTrailerDestination !== undefined) {
              pickupAndDelivery = driver.movements.filter((mov) => mov.stopData === null && mov.trailer_id === changeTrailerDestination.data.trailer_id);
            }
          }

          setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}  warningMasterData={data?.data?.options ?? []}  payload={payload} planId={planId} driver={driver} ApiName={'scheduleStackedOrders'} setSuccessResponseMssg={setSuccessResponseMssg} errormssg={str} pickupAndDelivery={pickupAndDelivery} /></div>)});
          return
       }else if(data?.data?.status=='failure'){
        errorMessageDisplay(data?.data?.errors);
       }else{
        setSuccessResponseMssg("Order scheduled successfully.")
       }
      }catch(err){
        console.log(err)
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Failed to schedule single and stack order', { autoClose: false } );
        }
      }
    }
    refreshDataFromAPI({
      "date": selectedTimezoneDate,
      "terminals": [selectedPlanningProfile.terminal_id]
    });
  };

  const requiredDataForDragAndDrop = (dropData, dragData, selectedTimezoneDate, PlanningBoardDriversResponse) => {
    const { drop_to, driver_id } = dropData;
    const dragDataItems = dragData.dragDataItem;
    const dropTime = calculateIndexTime(dropData, selectedTimezoneDate);
    const driver = PlanningBoardDriversResponse.filter((driver) => driver.driverId === driver_id)[0];
    const allMovementsOfSelectedDriver = driver.movements;

    return { drop_to, driver_id, dragDataItems, dropTime, driver, allMovementsOfSelectedDriver };
  };

  const calculateDiffInTimeFromSS=(driver,planId,dragDataItems,dropTime)=>{
    let dropTimeInISO
    const ss_stop = driver.movements.find((mov) => mov.movementData.pickup.stop_type === "SS" && mov.movementData.pickup.plan_id === planId);
    if(ss_stop?.movementData?.pickup?.actual_arrival_utc >0 || dragDataItems?.movementData?.pickup?.actual_departure_utc >0){
      errorMessageDisplay([{ message: "The actuals are filled in, the plan cannot be changed." }]);
      setDragData({});
      localStorage.removeItem("draggedData");
      refreshDataFromAPI({
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      });
      return null;
    }
    if (ss_stop) {
      const ss_stop_planned_arrive = ss_stop?.movementData?.pickup?.planned_arrive_utc;
      const dragged_stop_or_mov = dragDataItems?.movement?.stopData?.planned_arrive_utc ?? dragDataItems?.movement?.movementData.pickup.planned_depart_utc;

      if (dropTime?.dropTimeInMillis < ss_stop_planned_arrive) {
        const differenceInMillis = dragged_stop_or_mov - dropTime?.dropTimeInMillis;
        const calculated_SS_planned_arrive = ss_stop_planned_arrive - differenceInMillis;

        dropTimeInISO = DateTime.fromMillis(parseInt(calculated_SS_planned_arrive)).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
      } else {
        const differenceInMillis = dropTime?.dropTimeInMillis - dragged_stop_or_mov;
        const calculated_SS_planned_arrive = ss_stop_planned_arrive + differenceInMillis;

        dropTimeInISO = DateTime.fromMillis(parseInt(calculated_SS_planned_arrive)).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
      }
    }
    return dropTimeInISO
  }
   useEffect(() => {
    setPlannedOrUnplanned("center")
    
   }, [selectedTab])
   
  // New system's codes
  console.log("plannedOrUnplanned",plannedOrUnplanned)
  useEffect(() => {
    (async () => {
      if (isInitialRender) {
        setIsInitialRender(false);
        return;
      } else {
        if (isEditPermission) {
          let dragDataValue
          if(Object.keys(dragData)?.length==0){
            dragDataValue=JSON.parse(localStorage.getItem('draggedData'))
          }else{
            dragDataValue=dragData
          }
          if((dragDataValue?.plannedOrUnplanned  ?  dragDataValue?.plannedOrUnplanned == "center" : plannedOrUnplanned === "center") && dragDataValue?.dragFrom === "orders" && (dragDataValue?.dragDataItem?.Pickup_Actual_Arrive || dragDataValue?.dragDataItem?.Delivery_Actual_Arrive)){
              errorMessageDisplay([{message:"The actuals are filled in, the order cannot be scheduled"}]);
              setDragData({});
              localStorage.removeItem("draggedData");
              refreshDataFromAPI({
                "date": selectedTimezoneDate,
                "terminals": [selectedPlanningProfile.terminal_id]
              });
              return
          }
          if (((dragDataValue?.plannedOrUnplanned  ?  dragDataValue?.plannedOrUnplanned == "center" : plannedOrUnplanned === "center") && dragDataValue?.dragFrom === "orders") || dragDataValue?.dragFrom === "trailers" || dragDataValue?.dragFrom === "movements" || dragDataValue?.dragFrom === "Shift") {
       
            try {
              if (Object.keys(dropData).length > 0) {
                 // Required Variables
                 const { drop_to, driver_id, dragDataItems, dropTime, driver, allMovementsOfSelectedDriver } = requiredDataForDragAndDrop(dropData, dragDataValue, selectedTimezoneDate, PlanningBoardDriversResponse);  

                if (dragDataValue?.dragDataItem?.Driver_Load === 'M ' || dragDataValue?.dragDataItem?.Driver_Unload === 'M') {
                  setDriverLoadUnloadFlag(true);
                  setloadChange({ driver_load_unload: dragDataValue?.dragDataItem?.Driver_Load.trim() });
                  setunloadChange({ driver_load_unload: dragDataValue?.dragDataItem?.Driver_Unload.trim() });
                } else {
                  // Orders drag and drop
                  if (dragDataValue?.dragFrom === "orders" && drop_to === "planner_tick_container") {
                    await scheduleSingleAndStackOrder(allMovementsOfSelectedDriver, dropTime, dragDataItems, driver);
                  } else if (
                    dragData?.dragFrom === "Shift" &&
                    (drop_to === "planner_tick_container" ||
                      (dropData?.movement?.movementData?.delivery?.stop_type === "ES")) &&
                    (dragDataItems?.movement?.movementData?.pickup?.stop_type === "SS" || dragDataItems?.movement?.movementData?.delivery?.stop_type === "ES")
                  ) {
                    try {
                      if(dragDataItems?.movement?.movementData?.pickup?.actual_arrival_utc >0 || dragDataItems?.movement?.movementData?.pickup?.actual_departure_utc >0){
                        errorMessageDisplay([{ message: "The actuals are filled in, the plan cannot be changed." }]);
                        setDragData({});
                        localStorage.removeItem("draggedData");
                        refreshDataFromAPI({
                          "date": selectedTimezoneDate,
                          "terminals": [selectedPlanningProfile.terminal_id]
                        });
                        return
                      }

                      let driverMovements=driver.movements.filter((move)=>move.stopData===null && move.movementData.pickup.plan_id===dragDataItems.movement.movementData.pickup.plan_id && move.movementData.pickup.planned_arrive_utc > selectedTimezoneDate && move.movementData.pickup.planned_arrive_utc < selectedTimezoneDate+86400000 && move.movementData.delivery.stop_type==='ES')
                      
                     
                      if ((driverMovements.length>0 && driverMovements[0]?.movementData?.delivery?.location?.code ==="LAYOVER") || (dragDataItems?.movement?.movementData?.pickup?.location?.code ==="LAYOVER")) {
                        errorMessageDisplay([{ message: "The plan is in layover, so the plan time cannot be changed." }]);
                        setDragData({});
                        localStorage.removeItem("draggedData");
                        refreshDataFromAPI({
                          "date": selectedTimezoneDate,
                          "terminals": [selectedPlanningProfile.terminal_id]
                        });
                        return
                      }

                      let planId = dragDataItems?.movement?.movementData?.delivery?.plan_id
                      let dropTimeInISO=dropTime?.dropTimeInISO;
                      if(dragDataItems?.movement?.movementData?.delivery?.stop_type === "ES"){
                        dropTimeInISO= await calculateDiffInTimeFromSS(driver,planId,dragDataItems,dropTime);
                        if(dropTimeInISO==null){
                          return;
                        }
                      }
                      let response = await changePlanTimebystartshift(dropTimeInISO, planId);
                      if (response?.data?.status == 'warning') {
                        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
                        let pickupAndDelivery = [];

                        if (response?.data?.options.length > 0) {
                          const changeTrailerDestination = response?.data?.options.find((el) => el?.name === "Change trailer destination");

                          if (changeTrailerDestination !== undefined) {
                            pickupAndDelivery = driver.movements.filter((mov) => mov.stopData === null && mov.trailer_id === changeTrailerDestination.data.trailer_id);
                          }

                        }

                        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} warningMasterData={response?.data?.options ?? []} payload={dropTime?.dropTimeInISO} planId={planId} driver={driver} ApiName={'changePlanTimebystartshift'} setSuccessResponseMssg={setSuccessResponseMssg} errormssg={str} pickupAndDelivery={pickupAndDelivery} /></div>) });
                        return;
                      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
                        errorMessageDisplay(response?.data?.errors);
                      } else {
                        setSuccessResponseMssg("Change Plan Time successfull.");
                      }
                    } catch(err){
                      console.log(err)
                      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                        errorMessageDisplay(err?.response?.data?.errors);
                      }else{
                      toast.error('Failed to render shift in planned-unplanned tabs', { autoClose: false } );
                      }
                    }
                    refreshDataFromAPI({
                      "date": selectedTimezoneDate,
                      "terminals": [selectedPlanningProfile.terminal_id]
                    });
                  } else if (dragData?.dragFrom === "movements" && drop_to === "planner_tick_container") {
                    try {          
                      let dropTimeInISO;
                      if(dragDataItems?.movement?.movementData?.pickup?.actual_arrival_utc >0 || dragDataItems?.movement?.movementData?.pickup?.actual_departure_utc >0){
                        errorMessageDisplay([{ message: "The actuals are filled in, the plan cannot be changed." }]);
                        setDragData({});
                        localStorage.removeItem("draggedData");
                        refreshDataFromAPI({
                          "date": selectedTimezoneDate,
                          "terminals": [selectedPlanningProfile.terminal_id]
                        });
                        return
                      }
                      let planId = dragDataItems?.movement?.movementData?.delivery?.plan_id;
                      dropTimeInISO= await calculateDiffInTimeFromSS(driver,planId,dragDataItems,dropTime);
                      if(dropTimeInISO==null){
                        return;
                      }

                      let response = await changePlanTimebystartshift(dropTimeInISO, planId);

                      if (response?.data?.status == 'warning') {
                        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
                        let pickupAndDelivery = [];

                        if (response?.data?.options.length > 0) {
                          const changeTrailerDestination = response?.data?.options.find((el) => el?.name === "Change trailer destination");

                          if (changeTrailerDestination !== undefined) {
                            pickupAndDelivery = driver.movements.filter((mov) => mov.stopData === null && mov.trailer_id === changeTrailerDestination.data.trailer_id);
                          }
                        }
                        setWarningModalOpen(() => {
                          return (
                            <div>
                              <WarningModal
                                setWarningModalOpen={setWarningModalOpen}
                                warningMasterData={response?.data?.options ?? []}
                                payload={dropTime?.dropTimeInISO}
                                planId={planId} driver={driver}
                                ApiName={'changePlanTimebystartshift'}
                                setSuccessResponseMssg={setSuccessResponseMssg}
                                errormssg={str} pickupAndDelivery={pickupAndDelivery}
                              />
                            </div>
                          )
                        });
                        return;
                      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
                        errorMessageDisplay(response?.data?.errors);
                      } else {
                        setSuccessResponseMssg("Change Plan Time successfull.");
                      }
                    }
                    catch(err){
                      console.log(err)
                      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                        errorMessageDisplay(err?.response?.data?.errors);
                      }else{
                      toast.error('Failed to render warning', { autoClose: false } );
                      }
                    }
                    refreshDataFromAPI({
                      "date": selectedTimezoneDate,
                      "terminals": [selectedPlanningProfile.terminal_id]
                    });
                  } else if (dragDataValue?.dragFrom === "trailers" && drop_to === "trailer_row") {
                    try {   

                      const allMovsBtwTrailerStartToEnd = allMovementsOfSelectedDriver.filter((mov) => mov.stopData === null && (dropData?.trailer?.moveId?.includes(mov.movementData.load_id)));
                      let prevMove
                      let nextMove
                      if (allMovsBtwTrailerStartToEnd.length> 0) {
                        let checkForLoadedMove=allMovsBtwTrailerStartToEnd?.filter((mov)=>mov.type=='L') ?? [];
                        let finalMove=checkForLoadedMove?.length>0 ? checkForLoadedMove : [allMovsBtwTrailerStartToEnd[0]];
                        prevMove = allMovementsOfSelectedDriver.filter((e) => e.movementData.delivery.lm_stop_id === finalMove[0].movementData.pickup.lm_stop_id && e.stopData === null)
                        nextMove = allMovementsOfSelectedDriver.filter((e) => e.movementData.pickup.lm_stop_id === finalMove[0].movementData.delivery.lm_stop_id && e.stopData === null)
                        if ((finalMove[0].movementData.pickup.driver_load_unload?.trim() === "N" || finalMove[0].movementData.delivery.driver_load_unload?.trim() === "N")&& nextMove.length>0 && (nextMove[0]?.movementData.pickup.driver_load_unload?.trim() === "N" || nextMove[0]?.movementData.delivery.driver_load_unload?.trim() === "N")) {
                          if (nextMove[0].movementData.trailer_id === dragDataItems.Trailer_ID) {
                              errorMessageDisplay([{ message: "The load/unload flag for the stop is set to 'N', therefore the same trailer cannot be assigned" }]);
                              setDragData({});
                            localStorage.removeItem("draggedData");
                            refreshDataFromAPI({
                              "date": selectedTimezoneDate,
                              "terminals": [selectedPlanningProfile.terminal_id]
                            });
                            return
                          }
                        }
                        if ((finalMove[0].movementData.pickup.driver_load_unload?.trim() === "N" || finalMove[0].movementData.delivery.driver_load_unload?.trim() === "N") && prevMove.length>0 && (prevMove[0]?.movementData.pickup.driver_load_unload?.trim() === "N" || prevMove[0]?.movementData.delivery.driver_load_unload?.trim() === "N")) {
                          if (prevMove[0].movementData.trailer_id === dragDataItems.Trailer_ID) {  
                            errorMessageDisplay([{ message: "The load/unload flag for the stop is set to 'N', therefore the same trailer cannot be assigned" }]);
                            setDragData({});
                            localStorage.removeItem("draggedData");
                            refreshDataFromAPI({
                              "date": selectedTimezoneDate,

                              "terminals": [selectedPlanningProfile.terminal_id]
                            });
                            return
                          }
                        }
                      }                      
                      if (allMovsBtwTrailerStartToEnd?.length >0 && allMovsBtwTrailerStartToEnd[0].movementData.pickup.actual_departure_utc>0) {
                        errorMessageDisplay([{ message: "The actuals are filled in, the trailer cannot be assigned" }]);
                        setDragData({});
                        localStorage.removeItem("draggedData");
                        refreshDataFromAPI({
                          "date": selectedTimezoneDate,
                          "terminals": [selectedPlanningProfile.terminal_id]
                        });
                        return
                      }
                      const planId = allMovsBtwTrailerStartToEnd[0]?.movementData.pickup.plan_id;
                      const payload = {
                        movement_id: dropData?.trailer?.moveId,
                        trailer_id: dragDataItems.Trailer_ID
                      };

                      const response = await addTrailer(driver_id, planId, payload);
                      if (response?.data?.status == 'warning') {
                        let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";

                        let pickupAndDelivery = [];

                        if (response?.data?.options.length > 0) {
                          const changeTrailerDestination = response?.data?.options.find((el) => el?.name === "Change trailer destination");

                          if (changeTrailerDestination !== undefined) {
                            pickupAndDelivery = driver.movements.filter((mov) => mov.stopData === null && mov.trailer_id === changeTrailerDestination.data.trailer_id);
                          }

                        }

                        setWarningModalOpen(() => { return (<div><WarningModal setWarningModalOpen={setWarningModalOpen} warningMasterData={response?.data?.options ?? []} payload={payload} planId={planId} driver={driver} ApiName={'addTrailer'} setSuccessResponseMssg={setSuccessResponseMssg} errormssg={str} pickupAndDelivery={pickupAndDelivery} /></div>) });
                        return
                      } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
                        errorMessageDisplay(response?.data?.errors);
                      } else {
                        setSuccessResponseMssg("Trailer assigned successfully.");
                      }
                    } catch(err){
                      console.log(err)
                      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                        errorMessageDisplay(err?.response?.data?.errors);
                      }else{
                      toast.error('Failed to render unplanned planned tab contents', { autoClose: false } );
                      }
                    }
                    refreshDataFromAPI({
                      "date": selectedTimezoneDate,
                      "terminals": [selectedPlanningProfile.terminal_id]
                    });
                  }

                  setDragData({});
                  localStorage.removeItem("draggedData");
                }
              }
            } catch(err){
              console.log(err)
              if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                errorMessageDisplay(err?.response?.data?.errors);
              }else{
              toast.error('Failed to render planned unplanned contents', { autoClose: false } );
              }
              setDragData({});
              localStorage.removeItem("draggedData");
              refreshDataFromAPI({
                "date": selectedTimezoneDate,
                "terminals": [selectedPlanningProfile.terminal_id]
              });
            }
          }else if((dragDataValue?.plannedOrUnplanned  ?  dragDataValue?.plannedOrUnplanned == "center" : plannedOrUnplanned === "center" && dragDataValue?.dragFrom === "ordersFromTrailerView") || dragDataValue?.dragFrom === "movementFromTrailerView"){
            if(dragDataValue?.plannedOrUnplanned  ?  dragDataValue?.plannedOrUnplanned == "center" : plannedOrUnplanned === "center" && dragDataValue?.dragFrom === "ordersFromTrailerView"){      
            try {
                    if (Object.keys(dropData).length > 0) {
                      const dropTime = calculateIndexTime(dropData, selectedTimezoneDate);
                      let payload={
                      load_id:dragDataValue?.dragDataItem?.Move_ID,
                      planningDate:dropTime?.dropTimeInISO
                     }
                     const response = await addTrailerToorderfortrailerview(dropData?.trailer_id, payload);
                      if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
                      errorMessageDisplay(response?.data?.errors);
                     }else if(response?.data?.status=='failure'){
                      errorMessageDisplay([{message:'Failed to add trailer to order for trailer view.'}]);
                     }else{
                      setSuccessResponseMssg("Order assigned successfully.");   
                     }
                    }
                }
                catch(err){
                  console.log(err)
                  if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                    errorMessageDisplay(err?.response?.data?.errors);
                  }else{
                  toast.error('Failed to add trailer to order for trailer view.', { autoClose: false } );
                  }
                }
              }else if(dragDataValue?.dragFrom === "movementFromTrailerView"){
                try {
                  if (Object.keys(dropData).length > 0) {
                    const dropTime = calculateIndexTime(dropData, selectedTimezoneDate);
                    let payload={
                      load_id:dragDataValue?.dragDataItem?.movement?.movementData?.load_id                          ,
                      planningDate:dropTime?.dropTimeInISO
                     }
                  
                   const response = await updateTrailerdatafortrailerview(dropData?.trailer_id, payload);
                    if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
                    errorMessageDisplay(response?.data?.errors);
                   }else{
                    setSuccessResponseMssg("Trailer Data Updated successfully.");   
                   } 
                  }
                   }
                   catch(err){
                    console.log(err)
                    if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                      errorMessageDisplay(err?.response?.data?.errors);
                    }else{
                    toast.error('Failed to update trailer data for trailver view', { autoClose: false } );
                    }
                  }
              }
                   setDragData({});
                   localStorage.removeItem("draggedData");
                   setDropData({});
                   refreshDataFromAPI({
                     "date": selectedTimezoneDate,
                     "terminals": [selectedPlanningProfile.terminal_id]
                   });
          }
          localStorage.removeItem("draggedData");
        } else {
          setDragData({});
          localStorage.removeItem("draggedData");
          refreshData(payloadForRefreshData, movementData, driverData,false,null);
          return toast.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000)
        }
      };
    })();
  }, [dropData, datarefresh]);


  const setPlanningProfileHelperFunction = async(data,planning_profiles) => {
    let params=getDecodedURI(location.search);
    if(params?.planningProfile){
      let selectPlanningProfiles = planning_profiles?.find((el) => el?.terminal_id === params?.planningProfile);
      setselectedPlanningProfile(selectPlanningProfiles);
      setPlannerTimezone(getTimeZone(selectPlanningProfiles?.terminal?.timezone));

      if (params?.planningProfile && params?.driverId && params?.driverName) {
        setDriverMessageDetails({driverId: params?.driverId, driver_full_name: params?.driverName});
      }

      if(params?.selectedDate){
        setSelectedTimezoneDate(DateTime.fromISO(params?.selectedDate).setZone(getTimeZone(selectPlanningProfiles?.terminal?.timezone),{keepLocalTime : true}).startOf("day").toMillis());
        setPlannerTimezone(getTimeZone(selectPlanningProfiles.terminal.timezone));
      }else{
        if (data !== undefined) {
          setPlannerTimezone(getTimeZone(selectPlanningProfiles.terminal.timezone));
          setSelectedTimezoneDate(DateTime.now().setZone(getTimeZone(selectPlanningProfiles.terminal.timezone)).startOf("day").toMillis());
        }
      }
    }else{
    if (data !== undefined) {
      let params=getDecodedURI(location.search);
      params.planningProfile=data?.terminal_id
      history({pathname:location.pathname,search:`${getEncodedURI(params)}`});
      setselectedPlanningProfile(data);
      setPlannerTimezone(getTimeZone(data.terminal.timezone));
      setSelectedTimezoneDate(DateTime.now().setZone(getTimeZone(data.terminal.timezone)).startOf("day").toMillis());
    }
  }
  }

  const getPlanningProfileOfUser = async (planningProfilesData) => {
    let planningProfileData = await getPlanningProfileById(userData.id);
    setusersPlanningProfile(planningProfileData);

    let isCurrentlySelectedProfileEnable = false;

    if (userData?.userpreferences && userData?.userpreferences?.currentlySelectedPlanningProfile?.length > 0) {
      isCurrentlySelectedProfileEnable = userData?.userpreferences?.currentlySelectedPlanningProfile[0]?.terminal.use_planning;
    }

    if (userData.userpreferences &&
      userData?.userpreferences?.currentlySelectedPlanningProfile &&
      userData?.userpreferences?.currentlySelectedPlanningProfile.length > 0 && isCurrentlySelectedProfileEnable) {

      setPlanningProfileHelperFunction(userData?.userpreferences?.currentlySelectedPlanningProfile[0],planningProfilesData);
    } else {
      let assignedProfile = [...planningProfilesData].filter((profile) =>
        planningProfileData.map((assign) => assign.planningprofile_id)
          .includes(profile.id)
      );

      const unassignedProfile = planningProfilesData.filter(el => {
        return !planningProfileData.find(element => {
          return element.planningprofile_id === el.id;
        });
      });

      if (assignedProfile.length > 0) {
        const firstEnableAssignPlanningProfile = assignedProfile.filter((profile) => profile?.terminal?.use_planning === true);

        if (firstEnableAssignPlanningProfile.length > 0) {
          setPlanningProfileHelperFunction(firstEnableAssignPlanningProfile[0],planningProfilesData);
        } else {
          const firstEnableUnassignPlanningProfile = unassignedProfile.filter((profile) => profile?.terminal?.use_planning === true);

          setPlanningProfileHelperFunction(firstEnableUnassignPlanningProfile[0],planningProfilesData);
        }
      } else {
        const firstEnableUnassignPlanningProfile = unassignedProfile.filter((profile) => profile?.terminal?.use_planning === true);

        setPlanningProfileHelperFunction(firstEnableUnassignPlanningProfile[0],planningProfilesData);
      }
    }
  };

  const getTimeZone = (zone) => {
    let Timezone
    if (
      zone === "Eastern Standard Time" || zone === "America/New_York"
    ) {
      Timezone = "America/New_York";
    }
    if (
      zone === "Central Standard Time" || zone === "America/Chicago"
    ) {
      Timezone = "America/Chicago";
    }
    return Timezone
  };

  const getallPlanningProfile = async () => {
    let allPlanningProfile = await getAllPlanningProfiles();
    setallPlanningProfile(allPlanningProfile);
    return allPlanningProfile
  };

  const getAllPlanningProfilesFunction=async()=>{
    let planningProfiledata=await  getallPlanningProfile()
    getPlanningProfileOfUser(planningProfiledata)
  }

  useEffect(() => {
    if (userData.id) {
      getAllPlanningProfilesFunction()
    }
  }, [userData.id]);

  const carrierviewmovementdata = async (payload) => {
    const carrierViewMovementData = await getallcarrierview(payload);
    setcarrierviewData(carrierViewMovementData.data)
    setpalnningBoardLoading(false)
  };

  const createWashOrderHandler = () => {
    history({
      pathname: '/tankwash',
      state: { washDetails }
    })
  };

  const cancelWashCreateHandler = () => {
    setIsModalOpen(false)
    return toast.info("You Need to take the trailer somewhere else.")
  };

  const handlepuloadChange = (e, value) => {
    if (value) {
      setloadChange((prev) => ({
        ...prev,
        driver_load_unload: value.driver_load_unload,
      }));
    }
    // updateButtonState();
  };

  const handledelunloadChange = (e, value) => {

    if (value) {
      setunloadChange((prev) => ({
        ...prev,
        driver_load_unload: value.driver_load_unload,
      }));
    }
    // updateButtonState();
  };

  useEffect(() => {
    if (loadchange.driver_load_unload && unloadChange.driver_load_unload) {
      if (loadchange.driver_load_unload?.trim() !== 'M' && unloadChange.driver_load_unload?.trim() !== 'M') {
        setSaveButtonDisabled(false);
      }
    }
  }, [loadchange, unloadChange]);

  const updateloadunloaddata = async (e) => {
    let loadPayload = { loadFlag: loadchange.driver_load_unload };
    let unloadPayload = { unloadFlag: unloadChange.driver_load_unload };
 
    if (loadPayload.loadFlag === "M" || unloadPayload.unloadFlag === "M") {
      toast.error("load unload should not save with flag M", "Error");
 
    } else {
      const { dragDataItems, dropTime, driver, allMovementsOfSelectedDriver } = requiredDataForDragAndDrop(dropData, dragData, selectedTimezoneDate, PlanningBoardDriversResponse);
 
      await scheduleSingleAndStackOrder(allMovementsOfSelectedDriver, dropTime, dragDataItems, driver, loadPayload.loadFlag, unloadPayload.unloadFlag);
 
      setDriverLoadUnloadFlag(false);
      setSaveButtonDisabled(true);
      setDragData({});
      localStorage.removeItem("draggedData");
      refreshDataFromAPI({
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      });
    }
  };

  const closeModal = () => {
    setDriverLoadUnloadFlag(false);
    refreshDataFromAPI(
      {
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      }
    );
    setSaveButtonDisabled(true);
  };

  const handlecallTankwash = async () => {
    try {
      settabSelected("tankwash");
      let payload = { terminal_id: selectedPlanningProfile.terminal_id }
      let res = await getTrailerWashWorkOrdersforplanningboard(payload);
      setAllTankwash(res);
    }
    catch(err){
      console.log(err)
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Something went wrong while calling tankwash', { autoClose: false } );
      }
    }
  }

console.log("PlanningBoardTractorresponse",PlanningBoardTractorresponse)
  return (
    <DropContext.Provider
      value={{
        dragData,
        setDragData,
        dropData,
        setDropData,
        planningBoardData,
        setPlanningBoardData,
        payloadForOrders,
        quickRefresh,
        planningTimeout,
        setPlanningTimeout,
        refreshDataFromAPI,
        driverPageNumber,
        setDriverPageNumber,
        plannerTimezone,
        realTimeofPlanningBoard,
        setPlannerTimezone,
        movementData,
        setMovementData,
        setdatarefresh,
        datarefresh,
        payloadForRefreshData,
        driverViewPlannedMode,
        selectedTimezoneDate,
        setSelectedTimezoneDate,
        trailerTabLoading,
        PlannerBoardLoadingPage,
        setPlannerBoardLoadingPage,
        plannedOrUnplanned,
        setPlannedOrUnplanned,
        selectedPlanningProfile,
        settrailerViewPageNumber,
        trailerViewPageNumber,
        setTractorPageNumber,
        tractorPageNumber,
        selectedCommodityGroup,
        setSelectedCommodityGroup,
        findTrailersBasedOnCommodity,
        setFindTrailersBasedOnCommodity,
        settabSelected,
        driverMessageDetails,
        setDriverMessageDetails,
        setplannedAcctualToggle,
        plannedAcctualToggle,
        searchDriverData,
        searchTrailerData
      }}
    >
      <div>
        <div id='wrapper'>
          <Header
            userclicked={isPlannerBoardClicked}
            parentcallback={handelcallbackFromHeader}
          ></Header>
          <AppBar></AppBar>
          <div
            className={`content-page_yardcheck ${tabSelected != "" ? "planner_board_extra_bottom_space" : ""
              }`}
          >
            <div className='content'>
              <div className='container-fluid'>
                {isPlannerBoardClicked ? (
                  <></>
                ) : (
                  <>
                    <PlannerHeader
                      parrentCallBackForTab={parrentCallBackForTab}
                      setSearchData={setSearchData}
                      searchData={searchData}
                      searchHandler={searchHandler}
                    />
                    <div className='row mt_30'>
                      <div className='col-xl-12 planner_inner_card'>
                        <div className='card card_shadow'>

                          {
                            !palnningBoardLoading ?
                              <PlannerBoardTopSection
                                allPlanningProfile={allPlanningProfile}
                                setallPlanningProfile={setallPlanningProfile}
                                usersPlanningProfile={usersPlanningProfile}
                                setusersPlanningProfile={setusersPlanningProfile}
                                setselectedPlanningProfile={setselectedPlanningProfile}
                                selectedPlanningProfile={selectedPlanningProfile}
                                selectedTimezoneDate={selectedTimezoneDate}
                                setSelectedTimezoneDate={setSelectedTimezoneDate}
                                setpalnningBoardLoading={setpalnningBoardLoading}
                                palnningBoardLoading={palnningBoardLoading}
                                setplannedAcctualToggle={setplannedAcctualToggle}
                                plannedAcctualToggle={plannedAcctualToggle}
                                plannerTimezone={plannerTimezone}
                                setPlannerTimezone={setPlannerTimezone}
                                isEditPermission={isEditPermission}
                              />
                              : ""
                          }

                          {selectedTab === "carrier" ? (
                            <>
                              {
                                !palnningBoardLoading ?
                                  <PlannerBoardCarrierViewsection
                                    hourArr={hourArr}
                                    carrierviewData={carrierviewData}
                                    carrierPageNumber={carrierPageNumber}
                                    setCarrierPageNumber={setCarrierPageNumber}
                                  />
                                  : <ProgressBar animated now={100} />
                              }
                            </>
                          ) : selectedTab === "trailer" ? (
                            <>
                              {
                                !palnningBoardLoading ?
                                <>
                                  <PlannerBoardTrailersection
                                    hourArr={hourArr}
                                    dragset={dragset}
                                    allTrailers={allTrailers}
                                    selectedTimezoneDate={selectedTimezoneDate}
                                    tarilerViewData={tarilerViewData}
                                    settrailerViewPageNumber={settrailerViewPageNumber}
                                    trailerViewPageNumber={trailerViewPageNumber}
                                    isEditPermission={isEditPermission}
                                    setSuccessResponseMssg={setSuccessResponseMssg}
                                    realTimeofPlanningBoard={realTimeofPlanningBoard}
                                    plannerTimezone={plannerTimezone}
                                    searchTrailerData={searchTrailerData}
                                  />
                                   <div className='bottom_tab_wrapper'>
                                <div className='tab_button_section '>
                                  <button
                                    className={`tab_button ${tabSelected === "orders" ? "active_tab" : ""
                                      }`}
                                    onClick={(e) => {
                                      if (allOrders.length > 0) {
                                        settabSelected("orders")
                                      } else {
                                        settabSelected("orders")
                                      }
                                    }}
                                  >
                                    ORDERS
                                  </button>
                                </div>
                                {palnningBoardLoading ? (
                                  <div className='oredertab_loader_nomargin'>
                                    <ProgressBar animated now={100} />
                                  </div>
                                ) : tabSelected === "" ? null : tabSelected ===
                                  "orders" ? (
                                  !daysOutLoader ? (
                                    <PlannerBoardOrderTableTrailerview
                                      settabSelected={settabSelected}
                                      startdate={payloadForOrders.date}
                                      tabSelected={tabSelected}
                                      oderTabClickC={oderTabClickC}
                                      payloadForOrders={payloadForOrders}
                                      selectedPlanningProfile={selectedPlanningProfile}
                                      setplannedTab={setplannedTab}
                                      PlannedTab={PlannedTab}
                                      refreshDataFromAPI={refreshDataFromAPI}
                                      orderRefreshState={orderRefreshState}
                                      plannerTimezone={plannerTimezone}
                                    />
                                    ) : (
                                    <div className='oredertab_loader_nomargin'>
                                      <ProgressBar animated now={100} />
                                    </div>
                                  )
                                ) : ""}
                              </div>
                                </>  
                                  : <ProgressBar animated now={100} />
                              }
                            </>
                             ) : selectedTab === "tractor" ? (
                              <>
                                {
                                  !palnningBoardLoading ?
                                  <>
                                    <PlannerBoardTractorsection
                                    type='1'
                                    hourArr={hourArr}
                                    dragset={dragset}
                                    plannerDriverData={PlanningBoardTractorresponse}
                                    selectedTimezoneDate={selectedTimezoneDate}
                                    allTrailers={allTrailers}
                                    allTractors={allTractors}
                                    allDrivers={allTractors}
                                    tractorViewData={tractorData}
                                    profile={profile}
                                    plannerBoardLoadingPage={PlannerBoardLoadingPage}
                                    setPlannerBoardLoadingPage={setPlannerBoardLoadingPage}
                                    isEditPermission={isEditPermission}
                                    selectedPlanningProfile={selectedPlanningProfile}
                                    plannerTimezone={plannerTimezone}
                                    setSuccessResponseMssg={setSuccessResponseMssg}
                                    realTimeofPlanningBoard={realTimeofPlanningBoard}
                                    />
                                  </>  
                                    : <ProgressBar animated now={100} />
                                }
                              </>
                          ) : (
                            <>
                              {
                                !palnningBoardLoading ?
                                  <PlannerBoardDriversection
                                    type='1'
                                    refreshData={refreshData}
                                    setallDriver={setDriverData}
                                    hourArr={hourArr}
                                    dragset={dragset}
                                    plannerDriverData={PlanningBoardDriversResponse}
                                    selectedTimezoneDate={selectedTimezoneDate}
                                    allTrailers={allTrailers}
                                    allDrivers={driverData}
                                    profile={profile}
                                    plannerBoardLoadingPage={PlannerBoardLoadingPage}
                                    setPlannerBoardLoadingPage={setPlannerBoardLoadingPage}
                                    isEditPermission={isEditPermission}
                                    selectedPlanningProfile={selectedPlanningProfile}
                                    plannerTimezone={plannerTimezone}
                                    setSuccessResponseMssg={setSuccessResponseMssg}
                                    realTimeofPlanningBoard={realTimeofPlanningBoard}
                                    searchDriverData={searchDriverData}
                                  />
                                  : <ProgressBar animated now={100} />
                              }
                              <div className='bottom_tab_wrapper'>
                                <div className='tab_button_section' style={{justifyContent:"space-between"}}>
                                  <div style={{display:"flex"}}>
                                  <button
                                    className={`tab_button ${tabSelected === "orders" ? "active_tab" : ""
                                      }`}
                                    onClick={(e) => {
                                      if (allOrders.length > 0) {
                                        settabSelected("orders")
                                      } else {
                                        settabSelected("orders")
                                      }
                                    }}
                                  >
                                    ORDERS
                                  </button>
                                  <button
                                    className={`tab_button ${tabSelected === "trailers" ? "active_tab" : ""
                                      }`}
                                    onClick={(e) => {
                                      if (allTrailers.length >= 0) {
                                        settabSelected("trailers");
                                      }
                                    }}
                                  >
                                    TRAILERS
                                  </button>
                                  <button
                                    className={`tab_button ${tabSelected === "tankwash" ? "active_tab" : ""
                                      }`}
                                    onClick={(e) => {
                                      handlecallTankwash()
                                    }}
                                  >
                                    TANK WASH
                                  </button>
                                  </div>
                                  <div style={{paddingRight:"40px",paddingTop:"15px"}}>{version && !tabSelected ? 'Version '+'('+pakageJson.version+' - '+version+')' : ""}</div>
                                </div>
                                {palnningBoardLoading ? (
                                  <div className='oredertab_loader_nomargin'>
                                    <ProgressBar animated now={100} />
                                  </div>
                                ) : PlanningBoardDriversResponse.length === 0 ? (
                                  ""
                                ) : tabSelected === "" ? null : tabSelected ===
                                  "orders" ? (
                                  !daysOutLoader ? (
                                    <PlannerBoardOrdersTable
                                      settabSelected={settabSelected}
                                      startdate={payloadForOrders.date}
                                      tabSelected={tabSelected}
                                      oderTabClickC={oderTabClickC}
                                      payloadForOrders={payloadForOrders}
                                      selectedPlanningProfile={selectedPlanningProfile}
                                      setplannedTab={setplannedTab}
                                      PlannedTab={PlannedTab}
                                      refreshDataFromAPI={refreshDataFromAPI}
                                      orderRefreshState={orderRefreshState}
                                      isEditPermission={isEditPermission}
                                      plannerTimezone={plannerTimezone}
                                    />) : (
                                    <div className='oredertab_loader_nomargin'>
                                      <ProgressBar animated now={100} />
                                    </div>
                                  )
                                ) : tabSelected === "" ? null : tabSelected ===
                                "trailers" ? allTrailersArray.length >= 0 ? (
                                    <PlannerBoardTrailersTable
                                      settabSelected={settabSelected}
                                      allTrailersArray={allTrailers}
                                      tabSelected={tabSelected}
                                      trailerTabClickC={trailerTabClickC}
                                      selectedTimezoneDate={selectedTimezoneDate}
                                      refreshDataFromAPI={refreshDataFromAPI}
                                      selectedPlanningProfile={selectedPlanningProfile}
                                    />
                                  ) : "" : tabSelected === "" ? null : tabSelected ===
                                    "tankwash" ? alltankwash ? (
                                      <TankWashForPlanningBoard
                                        settabSelected={settabSelected}
                                        data={alltankwash}
                                        tabSelected={tabSelected}
                                        parentcallback={handlecallTankwash}
                                        refreshDataFromAPI={refreshDataFromAPI}
                                        selectedPlanningProfile={selectedPlanningProfile}
                                      />
                                    ) : "" : ""
                                  }
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={driverLoadUnloadFlag}
          onHide={() => closeModal()}
          // onShow={driverLoadUnloadFlag}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Update Load Unload
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(dragData?.dragDataItem?.Driver_Load === 'M ') && (
              <>
                <div className="col-md-12 six_pad">
                  <label for="typeHeadCommodityGroup">
                    PU Load Flag
                  </label>
                  <div className="meterial_autocomplete">

                    <Autocomplete
                      id="combo-box-demo"
                      options={puloadflag}
                      getOptionLabel={(option) => `${option.driver_load_unload}`}
                      value={loadchange}
                      onChange={handlepuloadChange}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Choose RedTag..."
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
            {(dragData?.dragDataItem?.Driver_Unload === 'M ') && (
              <>
                <div className="col-md-12 six_pad">
                  <label for="typeHeadCommodityGroup">
                    Del UnLoad Flag
                  </label>
                  <div className="meterial_autocomplete">
                    <Autocomplete
                      id="combo-box-demo"
                      options={delloadflag}
                      getOptionLabel={(option) => `${option.driver_load_unload}`}
                      value={unloadChange}
                      onChange={handledelunloadChange}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Choose RedTag..."
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => closeModal()}>Cancel</Button>
            <Button onClick={updateloadunloaddata} disabled={saveButtonDisabled} >Save</Button>
          </Modal.Footer>

        </Modal>


        <Modal
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Is Wash Required?</p>
          </Modal.Body>
          <Modal.Footer>

            <Button onClick={createWashOrderHandler}>Create Wash</Button>
            <Button onClick={cancelWashCreateHandler}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal> 

        {/* modal will open if api resonse is warning */}
        {
          warningModalOpen ? warningModalOpen : <></>
        }
      </div>
    </DropContext.Provider>
  );
};

export default Planner;
